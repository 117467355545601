.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  z-index: 0;
}

@font-face {
  font-family: StretchPro;
  src: url(StretchPro.otf);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: heartbeat-anim 2s infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: StretchPro;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1;
  
}

.App-link {
  color: #61dafb;
}

@keyframes heartbeat-anim
{
  0% {transform: scale(.95); 
    filter: drop-shadow(0 0 0 #000000);}
  70% {transform: scale(1);  
    filter: drop-shadow(0 0 10px #000000);}
  100%  {transform: scale(.95); 
    filter: drop-shadow(0 0 0 #000000);}
}

.glitch {
  position: relative;
  font-size: 4vw;
  font-style: italic;
  animation: color-glitch 4s infinite;
}

.glitch::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: -5px 0 red;
  background: black;
  overflow: hidden;
  top: 0;
  animation: color-glitch 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
}

.glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -5px 0 blue;
  background: black;
  overflow: hidden;
  top: 0;
  animation: color-glitch 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}


.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.02) .5%,
    transparent 1%
  );

  animation: fudge 7s ease-in-out alternate infinite;
}


@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}

.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: .8vw;
  color: rgba(165, 141, 141, .4);
  text-transform: uppercase;
  letter-spacing: 1em;
  text-align: center;
  animation: glitch-2 5s 5.02s infinite;
}

@keyframes glitch-2 {
  1%{
    transform: rotateX(10deg) skewX(70deg);
  }
  2%{
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes color-glitch {
  0% {
    text-shadow: -2px 3px 0 cyan, 2px -3px 0 red;
    transform: translate(var(--glitchz-translate));
    transform: rotateX(10deg) skewX(80deg) scale(1.5);
  }
  1% {
    text-shadow: 2px -3px 0 cyan, -2px 3px 0 red;
    transform: rotateX(0deg) skewX(0deg) scale(.2);
  }
  10% {
    text-shadow: -2px 3px 0 cyan, 2px -3px 0 red;
    transform: translate(var(--glitchz-translate));
    transform: rotateX(20deg) skewX(45deg) scale(.7);
  }
  11% {
    text-shadow: 2px -3px 0 cyan, -2px 3px 0 red;
    transform: rotateX(0deg) skewX(0deg) cale(2.5);
  }
  50% {
    text-shadow: -2px 3px 0 cyan, 2px -3px 0 red;
    transform: translate(var(--glitchz-translate));
    transform: rotateX(-10deg) skewX(70deg) scale(2);
  }
  51% {
    text-shadow: 2px -3px 0 cyan, -2px 3px 0 red;
    transform: rotateX(0deg) skewX(0deg) scale(.5);
  }
  2%, 100%, 9%, 12%, 49%, 52% {  text-shadow: none; transform: none; }
}

@keyframes glitch {
  1%{
    transform: rotateX(10deg) skewX(90deg);
  }
  2%{
    transform: rotateX(0deg) skewX(0deg);
  }
  49%{
    transform: rotateX(-10deg) skewX(-90);
  }
  50%{
    transform: rotateX(0deg) skewX(0deg);
  }
  
}
